<template>
  <div class="boxAh">
    <el-row class="dash-board">
    <el-button type="primary" class="questions">
      <el-link :href="'https://bentuilong.oss-cn-shenzhen.aliyuncs.com/imgs/beihai/560243058470290148.xlsx'">下载试题模板</el-link>
    </el-button>
    <el-button type="primary" v-permission="['Exercise:add']" class="reset" @click="addlys()">添加考试</el-button>
    <el-button type="primary" v-permission="['Exercise:select']" class="cha" @click="getone()">查询</el-button>
    <el-button type="warning" @click="reset" class="new">重置</el-button>
    <p class="studen" style="font-size: 12px">状态:</p>
    <p class="tai" style="font-size: 12px">课程名称:</p>
      <!-- <el-button class="sousuo">搜索</el-button> -->
    <el-input class="in2" value="" v-model="classnamecon"></el-input> <!--课程名称-->
    <el-select style="width: 150px" class="in1" v-model="status"><!--状态-->
        <el-option v-for="(i) in attendanceList" :key="i.value" :value="i.testStatus" :label="i.value"></el-option>
    </el-select>
    </el-row>
    <el-dialog
      :visible.sync="add"
      title='添加考试'
      width="60%"
    >
    <!-- <el-form :modal="classFrom" ref="classFrom" :rules="rules2"> -->
     <el-form :model="classFrom" ref="classFrom" :rules="rules2">
      <el-col :span="11">
      <el-form-item label="绑定班级" prop="courseName">
        <el-select
          @change="selectGet"
          value=""
          v-model="classFrom.courseName"
        >
          <el-option v-for="(i,index) in dataList"
                     :key="i.classId"
                     :label="i.className"
                     :value="index"
          ></el-option>
        </el-select>
    </el-form-item>
        <el-form-item label="考试名称" prop="testName">
          <el-input  style="width: 210px" v-model="classFrom.testName" value=""></el-input>
        </el-form-item>
      <el-form-item label="适用班级" prop="testApply">
        <el-input style="width: 210px" v-model="classFrom.testApply" value=""></el-input>
      </el-form-item>
      </el-col>
      <el-col :span="13">
      <el-form-item label="考试时长" prop="totalTime">
          <el-input style="width: 210px" v-model="classFrom.totalTime" value=""></el-input>
      </el-form-item>
      <el-form-item label="考试开始" prop="startTime">
                  <el-date-picker
                    v-model="classFrom.startTime"
                    type="datetime"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
      </el-form-item>
        <el-form-item label="考试结束" prop="endTime">
          <el-date-picker
            v-model="classFrom.endTime"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-form>
      <div>
        <el-button @click="add = false">取 消</el-button>
        <el-button @click="addpl('classFrom')">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog
     width="60%"
      :visible.sync="updataed"
      title='修改考试'
    ><el-form :modal="classfrom">
      <el-col :span="11">
        <el-form-item label="绑定班级">
          <el-select
            @change="selectGet"
            value=""
            v-model="classfrom.courseName"
          >
            <el-option v-for="(i,index) in dataList"
                       :key="i.classId"
                       :label="i.className"
                       :value="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试名称">
          <el-input  style="width: 210px" v-model="classfrom.testName" value=""></el-input>
        </el-form-item>
        <el-form-item label="适用班级">
          <el-input style="width: 210px" v-model="classfrom.testApply" value=""></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="考试时长">
          <el-input style="width: 350px" v-model="classfrom.totalTime" value=""></el-input>
        </el-form-item>
        <el-form-item label="考试时间">
          <el-date-picker
            v-model="classfrom.startTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
             style="width: 350px">
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-form>
      <div>
        <el-button @click="updataed = false">取 消</el-button>
        <el-button @click="updatadel()">提 交</el-button>
      </div>
    </el-dialog>
    <el-row class="bg">
    <el-table
      :data="tableData"
      tooltip-effect="dark">
      <el-table-column
        type="selection"
      >
      </el-table-column>
      <el-table-column
        label="课程编号"
        align="center"
        width="140" >
        <template slot-scope="scope">
          {{ scope.row.code }}
        </template>
      </el-table-column>
      <el-table-column
        label="课程名称"
        align="center"
        width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.courseName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="习题模块"
        align="center"
        width="80">
        <template slot-scope="scope">
         {{ scope.row.count }}
        </template>
      </el-table-column>
      <el-table-column
        label="习题数量"
        align="center"
        width="80">
        <template slot-scope="scope">
          {{ scope.row.num }}
        </template>
      </el-table-column>
      <el-table-column
        label="上传人"
        align="center"
        width="180">
        <template slot-scope="scope">
          {{ scope.row.courseName }}
        </template>
      </el-table-column>
      <el-table-column
        label="考试状态"
        align="center"
        width="100">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.testStatus == 0">未录入试题</el-tag>
          <el-tag type="warning" v-if="scope.row.testStatus == 1">待考试</el-tag>
          <el-tag type="success" v-if="scope.row.testStatus == 2">正在考试</el-tag>
          <el-tag type="info" v-if="scope.row.testStatus == 3">已结束</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="350">
        <template slot-scope="scope">
          <el-button size="mini" v-permission="['Exercise:edit']" v-if="scope.row.testStatus != 0" @click="updatatest(scope)">编辑考试</el-button>
          <el-button size="mini" v-permission="['Exercise:editTwo']" type="info" @click="updata(scope)">编辑</el-button>
          <el-button size="mini" v-permission="['Exercise:dele']" type="danger" @click="delet(scope.row)">删除</el-button>
          <el-button size="mini" v-permission="['Exercise:enter']" type="warning" @click="pather(scope)">录入考试</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-row>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { createTest, findTestInfo, delTestByTest, detailTest, updateTest } from '@/api/test.js'
  import { getAllClassByMoHu } from '@/api/user.js'
  export default {
    name: 'Exercise',
    data () {
      return {
        classnamecon: '',
        status: '',
        total: 0,
        pageNum: 1,
        pageSize: 10,
        updataed: false,
        attendanceList: [
          { testStatus: 0, value: '未录入试题' },
          { testStatus: 1, value: '待考试' },
          { testStatus: 2, value: '考试中' },
          { testStatus: 3, value: '已结束' }
        ],
        tableData: [{
          id: '',
          code: '',
          courseName: '',
          testStatus: '',
          count: '',
          num: ''
        }],
        dataList: [],
        classFrom: {
          startTime: '',
          endTime: '',
          className: '',
          testName: '',
          nameType: '',
          testTime: '',
          openTime: '',
          classId: ''
        },
        classfrom: {},
        add: false,
        statuser: '',
        rules2: {
          courseName: [{ required: true, message: '请选择班级', trigger: 'change' }],
          testName: [{ required: true, message: '请填写考试名称', trigger: 'change' }],
          testApply: [{ required: true, message: '请填写使用班级', trigger: 'change' }],
          totalTime: [{ required: true, message: '请填写考试时长', trigger: 'change' }],
          startTime: [{ required: true, message: '请选择考试开始时间', trigger: 'change' }],
          endTime: [{ required: true, message: '请选择考试结束时间', trigger: 'change' }]
        },
        query: {}
      }
    },
    mounted () { // 钩子函数
      this.getAllClassByMoHu() // 初始化班级
      this.findTestInfo() // 初始化考试
    },
    methods: {
      handleSizeChange(e) {
        this.pageSize = e
        this.findTestInfo()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.findTestInfo()
      },
      selectGet(index) {
        this.classFrom.classId = this.dataList[index].classId
        this.classFrom.className = this.dataList[index].className
      },
      addlys() {
        this.add = true
      },
      addpl(classFrom) {
        // addpl(classId) {
        this.dataList.forEach(item => {
          item.id = item.classId
        })
        this.$refs[classFrom].validate(valid => {
          if (valid) {
            const test = {
              startTime: this.classFrom.startTime,
              endTime: this.classFrom.endTime,
              courseName: this.classFrom.className,
              testName: this.classFrom.testName,
              testApply: this.classFrom.testApply,
              totalTime: this.classFrom.totalTime,
              classId: this.classFrom.classId
            }
            createTest(test).then(resp => {
              this[classFrom] = {}
              if (resp.data.code === '0') {
                this.$notify({
                  title: '成功',
                  message: '添加考试成功',
                  type: 'success'
                })
              }
              this.add = false
              this.findTestInfo()
            })
          }
        })
      },
      findTestInfo: function() {
//  findTestInfo(this.courseName, this.statuser).then(resp => {
         this.query = {
          courseName: this.classnamecon,
          status: this.status,
          pageSize: this.pageSize,
          pageNum: this.pageNum
        }
        findTestInfo(this.query).then(resp => {
          if (resp.data.code === '200') {
            this.total = resp.data.data.total // 查询总条数
            this.tableData = resp.data.data.rows // 接收返回的数据
          }
        })
      },
      getAllClassByMoHu: function () {
        getAllClassByMoHu(this.listQuery).then(resp => {
          if (resp.data.code === '0') {
            // this.total = resp.data.data.total
            this.dataList = resp.data.data.rows // 接收返回的数据
          }
        })
      },
      updatatest(scope) {
        const ModifyItem = scope.row.id
        this.$router.push({ path: '/updataConst', query: { id: ModifyItem } })
      },
      pather(scope) {
        const ModifyItem = scope.row.id
        this.$router.push({ path: '/Const', query: { id: ModifyItem } })
      },
      updata(scope) {
        this.updataed = true
        const ModifyItem = scope.row.id
        detailTest(ModifyItem).then(resp => {
          if (resp.data.code === '200') {
            // const that = this
            // this.total = resp.data.data.total // 查询总条数
            this.classfrom = resp.data.data // 接收返回的数据
            const startTime = []
            startTime.push(resp.data.data.startTime)
            startTime.push(resp.data.data.endTime)
            this.classfrom.startTime = startTime
            // this.$set(that.classFrom, 'startTime', [new Date(that.classFrom.startTime), new Date(that.classFrom.endTime)])
          }
        })
      },
      updatadel() {
        const test = {
          id: this.classfrom.id,
          courseName: this.classfrom.courseName,
          testName: this.classfrom.testName,
          testApply: this.classfrom.testApply,
          totalTime: this.classfrom.totalTime,
          // startTime: this.classfrom.startTime,
          classId: this.classfrom.classId,
          startTime: this.classfrom.startTime[0],
          endTime: this.classfrom.startTime[1]
        }
        updateTest(test).then(resp => {
          if (resp.data.code === '200') {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.updatadel = false
            this.findTestInfo()
          } else if (resp.data.code === '10003') {
            this.$notify({
              message: '考试正在进行与已结束不能进行修改',
              type: 'error'
            })
          }
        })
      },
      getone() {
        // const courseName = this.classnamecon
        // const statuser = this.TestStatus
        this.findTestInfo()
        // findTestInfo(courseName, statuser).then(resp => {})
      },
      delet(row) {
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((e) => {
          if (e === 'confirm') {
            console.log(row.id)
            delTestByTest(row.id).then(res => {
              if (res.data.code === '200') {
                this.$notify({
                  title: '删除成功',
                  duration: 1000,
                  type: 'success'
                })
                const index = this.dataList.indexOf(row)
                this.dataList.splice(index, 1)
                this.total--
                this.findTestInfo()
              }
            })
          }
        }).catch(() => {
        })
      },
      reset() {
        this.classnamecon = ''
        this.status = ''
      }
    }
  }
</script>

<style scoped>
  .boxAh{margin-top: -10px}
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  .dash-board {
    display: flex;
    background-color: white;
    height: 60px;
    padding: 10px 0;
    align-items: center;
  }
  .questions{
    position: absolute;
    left: 710px;
  }
  .reset{
    position: absolute;
    left: 90%;
  }
  .cha{
    position: absolute;
    left: 570px;
  }
  .new{
    position: absolute;
    left: 635px;
  }
  .in1{
    width: 120px;
    position: absolute;
    left: 70px;
    top: 14px;
  }
  .studen{
    position: absolute;
    font-size: 15px;
    left: 20px;
    top: 10px;
  }
  .tai{
    position: absolute;
    font-size: 15px;
    left: 300px;
  }
  .in2{
    width: 200px;
    position: absolute;
    top: 14px;
    left: 365px;
  }
  .sousuo{position: absolute; left: 43%;top: 14px;}
  .el-link.el-link--default {color: #fff;}
</style>
